import axios from "axios";
const getCorporateLandingPageinfo = (request) => {
  return new Promise((res, rej) => {
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("consumer_token");
    axios
      .post("/getLandingPageInfo", request)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => {
        rej(err);
      });
  });
};

const sendLink = (request) => {
  return new Promise((res, rej) => {
      axios.post('/sendLandingLink', request)
          .then((response) => {
              res(response.data);
          })
          .catch((err) => {
              rej(err);
          })
  })
};

export default {
  getCorporateLandingPageinfo,
  sendLink
};
